import moment from "moment";
import { defineStore } from "pinia";
import { useLinksTableStore } from "~/store/useLinksTableStore";

export const useAnalyticsStore = defineStore("analyticsStore", () => {
  const { setSearchInput } = useLinksTableStore();

  const defaultDateRange = {
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
  };

  // Overview
  const selectedDateRange = ref(defaultDateRange);

  const updateSelectedDateRange = (newRange) => {
    console.log("🚀 ~ updateSelectedDateRange ~ newRange:", newRange);
    const { startDate, endDate } = newRange;

    if (startDate === "" || endDate === "") return;

    // Convert to moment objects and set the correct times
    const start = moment(startDate, "DD MMM YYYY").startOf("day");
    const end = moment(endDate, "DD MMM YYYY").endOf("day");

    const currentRange = selectedDateRange.value;
    if (!start.isSame(currentRange.start) || !end.isSame(currentRange.end)) {
      selectedDateRange.value = { startDate: start, endDate: end };
    }
  };

  // Filters
  const filters = ref({
    browser: [],
    referral: [],
    domain: [],
    region: [],
    state: [],
    city: [],
  });

  const filtersValues = computed(() => Object.values(filters.value).flat());

  const addFilter = (category, value) => {
    if (!filters.value[category].includes(value)) {
      filters.value[category].push(value);
    }
  };

  const removeFilter = (value) => {
    Object.entries(filters.value).forEach(([category, values]) => {
      const index = values.indexOf(value);
      if (index !== -1) {
        filters.value[category].splice(index, 1);
        return; // Exit the loop early
      }
    });
  };

  const resetFilters = () => {
    selectedDateRange.value = defaultDateRange;
    setSearchInput("");
    Object.keys(filters.value).forEach((key) => {
      filters.value[key] = [];
    });
  };

  return {
    selectedDateRange,
    updateSelectedDateRange,

    filters,
    filtersValues,
    addFilter,
    removeFilter,
    resetFilters,
  };
});
